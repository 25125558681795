<template>
  <div style="text-align: left">{{ isaddpro ? "创建商品" : "修改信息" }}</div>
  <el-form
    :model="addgoodForm"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    style="margin-top: 30px"
    class="demo-ruleForm"
  >
    <el-form-item label="商品名称" prop="name" style="width: 50%">
      <div style="display: flex">
        <el-input
          v-model="addgoodForm.name"
          clearable
          placeholder="请输入商品名称"
          style="width: 230px"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="商品分类" prop="categoryId" style="width: 50%">
      <div style="display: flex">
        <el-select
          v-model="addgoodForm.categoryId"
          clearable
          style="width: 150px"
          placeholder="请选择商品分类！"
        >
          <el-option
            v-for="item in categoryOption"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </div>
    </el-form-item>
    <el-form-item label="商品图片" class="goodpic" style="width: 50%">
      <el-upload
        :action="baseUrl + '/nktdec/uploader/uploadfile'"
        list-type="picture-card"
        style="display: flex; justify-content: flex-start"
        :auto-upload="false"
        accept=".mp4,.jpg,.jpeg,.png"
        :on-change="filechange"
        :file-list="goodbasefileList"
        :limit="8"
        :on-exceed="handleexceed"
      >
        <template #default>
          <i class="el-icon-plus"></i>
        </template>
        <template #file="{ file }">
          <!-- {{ file }} -->
          <div style="width: 100%; height: 100%">
            <video
              style="width: 100%; height: 100%"
              v-if="file.raw && file.raw.type === 'video/mp4'"
              :src="file.url"
            ></video>
            <img
              v-else
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <!-- <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span> -->
              <span
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </template>
      </el-upload>
      <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
        >建议宽高比1：1, 最多上传8张, 第1张将作为商品首图</span
      >
    </el-form-item>
    <el-form-item label="划线价格" prop="price" style="width: 50%">
      <div class="displayflex">
        <el-input
          v-model="addgoodForm.price"
          clearable
          placeholder="请输入商品价格！"
          style="width: 150px"
          @change="changetonum"
        ></el-input>
        <span style="margin-left: 15px">元</span>
      </div>
      <div style="text-align: left">
        <span style="color: rgba(0, 0, 0, 0.45)"
          >此价格用于商品展示，最低0.01</span
        >
      </div>
    </el-form-item>

    <el-form-item label="商品简介" prop="content" style="width: 50%">
      <div style="display: flex">
        <el-input
          v-model="addgoodForm.content"
          clearable
          placeholder="请输入商品得简要描述！"
          style="width: 230px"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="商品描述" prop="description" style="width: 80%">
      <vue-ueditor-wrap
        v-model="addgoodForm.description"
        :config="editorConfig"
        editor-id="goodsInfo"
        style="height: 100%"
      ></vue-ueditor-wrap>
    </el-form-item>
  </el-form>
  <div class="displayflex">
    <el-button
      type="primary"
      size="mini"
      style="margin-left: 30px"
      @click="submitInfo"
      >提交</el-button
    >
  </div>
  <el-dialog v-model="dialogVisible">
    <img
      v-if="fileType === 0"
      width="100%"
      height="500px"
      :src="dialogImageUrl"
      alt=""
    />
    <video
      v-else
      :src="dialogImageUrl"
      controls
      style="width: 80%; height: 500px"
    ></video>
  </el-dialog>
</template>
<script>
// import _ from 'lodash'
export default {
  data() {
    return {
      addgoodForm: {
        name: '',
        categoryId: '',
        content: '',
        description: '',
        price: '',
        mediaUrl1: '',
        mediaUrl2: '',
        mediaUrl3: '',
        mediaUrl4: '',
        mediaUrl5: '',
        mediaUrl6: '',
        mediaUrl7: '',
        mediaUrl8: '',
      },
      baseUrl: '',
      goodbasefileList: [],
      goodbaseproArr: [],
      isaddpro: true,
      goodsStatus: 'true',
      waitupgoodsfileList: [],
      waitupgoods: {},
      dialogVisible: false,
      fileType: 0,
      dialogImageUrl: '',
      categoryOption: [],
      imgBaseUrl: '',
      rules: {
        name: [
          { required: true, message: '请输入商品名称！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        categoryId: [
          { required: true, message: '请至少选取一个商品分类！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '请输入商品价格！', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入商品简介！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        goodStatus: [
          { required: true, message: '请输入商品状态！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      },
      activeName: '1'
    }
  },
  methods: {
    async getcategory() {
      const res = await this.$http.get('/nktdec/categoryinfo/categoryinfos?type=PRODUCT')
      this.categoryOption = res.data
      console.log(this.categoryOption)
    },
    handleexceed() {
      this.$message.error('仅限上传8个媒体文件！')
    },
    handleRemove(file) {
      console.log(file)
      const list = this.goodbasefileList
      var i = list.findIndex(item => item.url === file.url)
      // var arr = this.cousepicList.filter(item => item.url !== file.url)
      // this.cousepicList = arr
      console.log(i)
      list.splice(i, 1)
      this.goodbasefileList = list
      // console.log(file.url.split('/'))
      var tmpArr = file.url.split('/')
      var j = this.goodbaseproArr.findIndex(item => item === tmpArr[tmpArr.length - 1])
      this.goodbaseproArr.splice(j, 1)
      console.log(this.goodbaseproArr)
      console.log('绑定的文件列表', this.goodbasefileList)
    },
    logcontent() {
      console.log(this.addgoodForm)
    },
    async getfilelist(filename) {
    },
    async filechange(file, fileList) {
      const formData = new FormData()
      formData.append('file', file.raw)
      formData.append('params', '参数')
      const res = await this.$http(
        {
          method: 'post',
          url: '/nktdec/uploader/uploadfile',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data;',
          }
        }
      )
      console.log(res)
      this.goodbasefileList.push({
        url: this.imgBaseUrl + res.data.result
      })
      this.goodbaseproArr.push(res.data.result)
      console.log(this.goodbasefileList)
    },
    changetonum() {
      this.addgoodForm.price = parseFloat(this.addgoodForm.price).toFixed(2)
      console.log(this.addgoodForm.price)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
      if (file.raw.type === 'video/mp4') {
        this.fileType = 1
      } else {
        this.fileType = 0
      }
      console.log(file.raw.type)
    },
    async submitInfo() {
      if (this.goodbaseproArr[0].trim() === '') {
        this.$message.error('请上传图片！')
        return
      }
      if (this.isaddpro) {
        this.goodbaseproArr.forEach((item, index) => {
          this.addgoodForm['mediaUrl' + (index + 1)] = item
        })
        const res = await this.$http.post('/nktdec/productinfo/productinfos', this.addgoodForm)
        console.log(res)
        if (res.data.code === '1') {
          this.$message.success('添加成功')
          this.$router.push('/goodslist')
        }
        // this.goodbaseproArr.forEach(async (item) => {
        //   const data = await this.$http.get('/nktdec/uploader/images/' + item)
        //   if (!this.waitupgoodsfileList.some(itemitem => this.baseUrl + data.config.url.substring(1) === itemitem.url)) {
        //     this.waitupgoodsfileList.push({
        //       url: this.baseUrl + data.config.url.substring(1)
        //     })
        //   }
        // })
      } else {
        console.log(this.goodbaseproArr)
        console.log('绑定的文件列表', this.goodbasefileList)
        this.addgoodForm.mediaUrl1 = ''
        this.addgoodForm.mediaUrl2 = ''
        this.addgoodForm.mediaUrl3 = ''
        this.addgoodForm.mediaUrl4 = ''
        this.addgoodForm.mediaUrl5 = ''
        this.addgoodForm.mediaUrl6 = ''
        this.addgoodForm.mediaUrl7 = ''
        this.addgoodForm.mediaUrl8 = ''
        this.goodbaseproArr.forEach((item, index) => {
          this.addgoodForm['mediaUrl' + (index + 1)] = item
        })
        console.log('提交数据', this.addgoodForm)
        const res = await this.$http.post('/nktdec/productinfo/productinfos', this.addgoodForm)
        console.log(res)
        this.$router.push('/goodslist')
        this.isaddpro = true
      }
    },
    async getmediaInfo(img) {
      const res = await this.$http.get('/nktdec/uploader/images/' + img)
      console.log(res)
      // this.goodbasefileList
    }
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '商品管理',
        index: '/goodslist'
      },
      {
        name: '商品列表',
        index: '/goodslist'
      }, {
        name: '商品编辑',
        index: ''
      }]
    this.$store.commit('setBreadInfo', Arr)
    console.log(this.$route.params.isadd === 'false')
    this.baseUrl = this.$http.defaults.baseURL
    this.imgBaseUrl = 'http://nktdec.ctvideo.cn/images/'
    this.getcategory()
    if (this.$route.params.isadd === 'false') {
      this.isaddpro = false
      this.addgoodForm = this.changeGoodsInfo
      this.addgoodForm.price = this.changeGoodsInfo.basePrice
      console.log(this.changeGoodsInfo)
      // this.addgoodForm.price = this.changeGoodsInfo.basePrice
      this.goodbaseproArr = []
      const list = []
      setTimeout(async () => {
        for (let i = 1; i < 9; i++) {
          if (this.addgoodForm['mediaUrl' + i].trim() !== '') {
            const result = await this.$http.get('/nktdec/uploader/images/' + this.addgoodForm['mediaUrl' + i])
            console.log(result)
            // this.goodbasefileList[i - 1] = {
            //   url: this.baseUrl + result.config.url.substring(1)
            // }
            list.push({
              url: this.imgBaseUrl + this.addgoodForm['mediaUrl' + i]
            })
            this.goodbaseproArr.push(this.addgoodForm['mediaUrl' + i])
            console.log(this.goodbasefileList)
          } else {
            break
          }
        }
        this.goodbasefileList = list
      }, 0)
    }
  },
  computed: {
    changeGoodsInfo() {
      return this.$store.state.changeGoodsInfo
    },
    editorConfig() {
      return this.$store.state.editorConfig
    }
  },
}
</script>
<style scoped>
</style>
